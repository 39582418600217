import React, { useState, useRef, useEffect } from "react";
import { Affix, Anchor } from "antd";
import { useScroll } from "@umijs/hooks";
import PropTypes from "prop-types";
import { isMobile } from "react-device-detect";
import { get } from "lodash";

import { ContentBlock } from "../../components/Blocks";
import { TextBlock } from "../../components/Blocks";
import Pages from "../../components/Pages";
import Breadcrumbs from "../../components/Layout/Breadcrumbs";
import SCarousel from "../../components/SCarousel";
import { removePreloader } from "../../helpers";
import seoData from "../../components/SEO/data";

import "./history.scss";

const { Link } = Anchor;

export default function History() {
	const block = useRef(null);
	const container = useRef(null);

	const [scroll] = useScroll(typeof document !== "undefined" ? document : container);
	const [slider, setSlider] = useState(null);
	const [offset, setOffset] = useState(100);

	const items = [
		{
			id: "5f1f02f4-8c5a-4972-84d8-999a4cb2824f",
			img: {
				src: "https://storage.yandexcloud.net/serafim-uploads/content/2020/10/27/1.-_7ccd1791-daee-46ec-93c7-0a6c4bf695d2.jpg",
				alt: "Спасо-Преображенский мужской монастырь",
				type: "old",
			},
			title: "Спасо-Преображенский мужской монастырь",
			description:
				<div>
					<p>
						<b>1555 год</b> — примерная дата основания Спасо-Преображенского мужского монастыря на территории современного Арзамаса
					</p>
					<p>
						<b>1558 год</b> — начало истории села Дивеева
					</p>
				</div>
			,
		},
		{
			id: "17f3022b-fb95-40c8-b5a6-8eabe6fb5s4244e3d",
			img: {
				src: "https://storage.yandexcloud.net/serafim-uploads/content/2020/10/27/2._330d13de-47ec-4c32-a282-ec0a83386b3a.jpg",
				alt: "Арзамас",
				type: "old",
			},
			title: "Арзамас",
			description:
			<div>
				<p><b>1578 год</b> — условная дата основания Арзамаса</p>
			</div>,
		},
		{
			id: "779ad2bc-a3dc-44g453a-a9fd-5cad176ee81a",
			img: {
				src: "https://storage.yandexcloud.net/serafim-uploads/content/2020/10/27/3._a3d5faf6-9a12-4c0c-a5f0-326e3823a8fd.jpg",
				alt: "Николаевский женский монастырь",
				type: "old",
			},
			title: "Николаевский женский монастырь",
			description:
				<div>
					<p><b>1580 год</b> — основание Николаевского женского монастыря в Арзамасе</p>
					<p><b>1634 год</b> — основан Арзамасский Новодевичий во имя Алексия, человека Божия женский монастырь</p>
					<p><b>1643 год</b> — построена соборная церковь Преображения в Спасо-Преображенском монастыре в Арзамасе. Древнейший из сохранившихся архитектурных памятников города</p>
				</div>,
		},
		{
			id: "3936cc38-a5d9-4450-b93b-bw4f941a174d29d",
			img: {
				src: "https://storage.yandexcloud.net/serafim-uploads/content/2020/10/27/4._41984041-d8f2-463f-9d7b-5087dce17d19.jpg",
				alt: "Саровская пустынь",
				type: "old",
			},
			title: "Саровская пустынь",
			description:
				<div>
					<p><b>1706 год</b> — основан монастырь — Сатисо-Градо-Саровская пустынь — у слияния двух рек — Сатиса и Саровки</p>
					<p><b>1708 год</b> — образование Арзамасской провинции</p>
					<p><b>1726 год</b> — произошел большой пожар, уничтоживший крепостную стену и Николаевский женский монастырь в Арзамасе</p>
					<p><b>1738 год</b> — восстановлена каменная Никольская церковь Николаевского женского монастыря в Арзамасе</p>
				</div>,
		},
		{
			id: "3936cc38-a5d9-445g50-b93b-b941a145d29d",
			img: {
				src: "https://storage.yandexcloud.net/serafim-uploads/content/2020/10/27/5._6d973b8a-4fa4-433e-9023-b654dfe54cd3.jpg",
				alt: "Успенский собор в Саровской пустыни",
				type: "transitor",
			},
			title: "Успенский собор в Саровской пустыни",
			description:
				<div>
					<p><b>1744 год</b> — в Саровской пустыни возведен и освящен первый каменный храм — церковь Успения</p>
				</div>,
		},
		{
			id: "3936cc38-a54g5d9-4450-b93b-b9545452a174d29d",
			img: {
				src: "https://storage.yandexcloud.net/serafim-uploads/content/2020/10/27/6._4d3932fa-311b-4743-85aa-a77469faf8be.jpg",
				alt: "Прохор Мошнин",
				type: "transitor",
			},
			title: "Прохор Мошнин",
			description:
				<div>
					<p><b>19 июля (1 августа н. ст.) 1754 года</b> — в Курске родился Прохор Мошнин, ставший в дальнейшем великим подвижником и известный как преподобный Серафим Саровский</p>
					<p><b>1764 год</b> — упразднен Арзамасский Алексеевский монастырь. Казанская и Алексеевская церкви стали приходскими, все имущество обители было передано Арзамасскому Николаевскому женскому монастырю</p>
					<p><b>1777 год</b> — освящен главный собор Саровской пустыни — Успенский собор</p>
					<p><b>1778 год</b> — Прохор Мошнин стал послушником Саровской пустыни</p>
				</div>,
		},
		{
			id: "3936cc38-a545546d9-4450-b93b-b941a154674d29d",
			img: {
				src: "",
				alt: "Казанский храм в Дивееве",
				type: "transitor",
			},
			title: "Казанский храм в Дивееве",
			description:
				<div>
					<p><b>1779 год</b> — в Дивееве построен каменный храм во имя иконы Казанской Божией Матери.Возведение каменной церкви велось на личные средства преподобной Александры(в миру Агафия Семеновна Мельгунова) на том месте, где ей явилась Пресвятая Богородица</p>
					<p><b>1786 год</b> — Прохор Мошнин пострижен в монашество с именем Серафим в Саровской пустыни</p>
					<p><b>1788 год</b> — начало Казанской женской общины в Дивееве</p>
					<p><b>1789 год</b> — духовное руководство Дивеевской общиной было поручено отцу Серафиму</p>
					<p><b>1802 год</b> — открытие А.В.Ступиным школы живописи в Арзамасе.Учреждение существовало до 1861 года</p>
					<p><b>1826 год</b> — начало Мельничной женской общины в Дивееве.Закладка мельницы на месте, указанном преподобному Серафиму Царицей Небесной</p>
				</div>,
		},
		{
			id: "3936cc35468-a5d9-4450-b92е53b-b945461a174d29d",
			img: {
				src: "https://storage.yandexcloud.net/serafim-uploads/content/2020/10/27/8..XIXXX_c3ecc8bb-81bb-42a8-86d8-3e49d3367b96.jpg",
				alt: "Кончина преподобного Серафима Саровского",
				type: "transitor",
			},
			title: "Кончина преподобного Серафима Саровского",
			description:
				<div>
					<p><b>2 января (15 января н. ст.) 1833 года</b>  — преподобный Серафим Саровский отошел ко Господу в Саровской пустыни</p>
					<p><b>1842 год</b> — объединение Казанской и Мельничной общин в Дивееве</p>
				</div>,
		},
		{
			id: "3936cc36548-a5е25е5d9-4450-b93b-b546941a174d29d",
			img: {
				src: "https://storage.yandexcloud.net/serafim-uploads/content/2020/10/27/9._7e3d79be-4f91-40bd-8069-deb4a63d9e7a.jpg",
				alt: "Воскресенский собор",
				type: "transitor",
			},
			title: "Воскресенский собор",
			description:
				<div>
					<p><b>1842 год</b> — построен Воскресенский собор в Арзамасе. Строительство длилось 28 лет</p>
					<p><b>1861 год</b> — возведение Дивеевской женской общины в степень монастыря по Указу Святейшего Синода. Игуменьей стала монахиня Мария (Елисавета Алексеевна Ушакова)</p>
				</div>,
		},
		{
			id: "3936cc4554638-a5d9-4450-b93b-b941a174d29d",
			img: {
				src: "https://storage.yandexcloud.net/serafim-uploads/content/2020/10/27/10._d505a620-1816-493c-babe-296710b5b339.jpg",
				alt: "Троицкий собор в Дивееве",
				type: "transitor",
			},
			title: "Троицкий собор в Дивееве",
			description:
				<div>
					<p><b>1875 год</b> — построен Троицкий собор на территории Серафимо- Дивеевского монастыря </p>
					<p><b>1901 год</b> — начало движения по железной дороге Нижний Новгород — Арзамас.На окраине города появилась станция Арзамас - 1</p>
				</div>,
		},
		{
			id: "3936cc38-a5d9-4450-b93b-b65345941a174d29d",
			img: {
				src: "https://storage.yandexcloud.net/serafim-uploads/content/2020/10/27/11.._e979f3f5-dc91-4767-8cc3-bf0525f3e4cf.jpg",
				alt: "Канонизация преподобного Серафима Саровского",
				type: "transitor",
			},
			title: "Канонизация преподобного Серафима Саровского",
			description:
				<div>
					<p><b>1903 год</b> — преподобный Серафим Саровский причислен к лику святых Русской православной церкви</p>
					<p><b>1903 год</b> — совершен чин канонизации преподобного Серафима Саровского в Саровской пустыни в присутствии императора Российской империи Николая II и его супруги</p>
					<p><b>1909 год</b> — открылось движение по Московско- Казанской железной дороге.Появилась станция Арзамас - 2</p>
				</div>,
		},
		{
			id: "3933456cc38-a5d9-4450-b93b-b941a174d29d",
			img: {
				src: "https://storage.yandexcloud.net/serafim-uploads/content/2020/10/27/13._a5d10d67-8ffb-4478-acbb-f090207229e3.jpg",
				alt: "Преображенский собор в Дивееве",
				type: "tv",
			},
			title: "Преображенский собор в Дивееве",
			description:
				<div>
					<p>1916 год — завершено строительство Преображенского собора на территории Серафимо-Дивеевского монастыря</p>
					<p>1919 год — Серафимо-Дивеевский монастырь преобразовали в трудовую артель</p>
					<p>1920 год — Николаевский женский монастырь в Арзамасе преобразовали в трудовую артель</p>
					<p>1920 год — изъятие мощей преподобного Серафима Саровского</p>
				</div>,
		},
		{
			id: "3933456cc38-a5d9-4450-b93b-b941a176524d29d",
			img: {
				src: "https://storage.yandexcloud.net/serafim-uploads/content/2020/10/27/15.-_52cc0dce-0494-479a-bebb-e72a320209d2.jpg",
				alt: "Спасо-Преображенский монастырь в Арзамасе, советское время",
				type: "tv",
			},
			title: "Спасо-Преображенский монастырь в Арзамасе, советское время",
			description:
				<div>
					<p><b>1920-е годы</b> — закрытие Спасо-Преображенского мужского монастыря в Арзамасе</p>
				</div>,
		},
		{
			id: "3ki876933456cc38-a5d9-4450-b93b-b941a176524d29d",
			img: {
				src: "https://storage.yandexcloud.net/serafim-uploads/content/2020/10/27/16._4352fa34-f3ec-44f2-839a-d217380b6313.jpg",
				alt: "Дивеевский монастырь, советское время",
				type: "tv",
			},
			title: "Дивеевский монастырь, советское время",
			description:
				<div>
					<p><b>1927 год</b> — закрытие Серафимо-Дивеевского монастыря</p>
				</div>,
		},
		{
			id: "3ki876933456cc38-a5d9-4450-b93b-b941a1765i87y24d29d",
			img: {
				src: "https://storage.yandexcloud.net/serafim-uploads/content/2020/10/27/18._52819486-1d6e-4f69-aa69-cfda2e266432.jpg",
				alt: "Саровский монастырь, советское время",
				type: "tv",
			},
			title: "Саровский монастырь, советское время",
			description:
				<div>
					<p><b>1927 год</b> — закрытие Саровского монастыря</p>
					<p><b>1928 год</b> — закрытие Николаевского женского монастыря в Арзамасе до 1936 года — разрушены все места в саровском лесу, связанные с преподобным Серафимом Саровским</p>
					<p><b>1946 год</b> — создание конструкторского бюро КБ-11 по разработке ядерного оружия на месте поселка Саров, где располагалась Саровская пустынь</p>
					<p><b>1951 год</b> — взорван Успенский собор Саровской пустыни</p>
					<p><b>1953 год</b> — взорвана церковь Пресвятой Богородицы, Жиfvвоносного Ее Источника в Сарове</p>
					<p><b>1954 год</b> — образована Арзамасская область (упразднена в конце апреля 1957 года</p>
					<p><b>1954 год</b> — Саров получил статус города</p>
					<p><b>1988 год</b> — возрождение в селе Дивееве церковной жизни</p>
					<p><b>1990 год</b> — вновь был освящен Троицкий собор Серафимо-Дивеевского монастыря</p>
					<p><b>1991 год</b> — возобновление монашеской жизни в Серафимо-Дивеевском монастыре</p>
					<p><b>30 июля 1991 года</b> — в Дивеево прошел крестный ход со святыми мощами преподобного Серафима Саровского. В Троицком соборе установлена рака с мощами святого подвижника</p>
					<p><b>1992 год</b> — принят закон Российской Федерации «О закрытом административно-территориальном образовании», определяющий статус города Сарова и особые условия его существования</p>
					<p><b>1994 год</b> — начало восстановления Николаевского женского монастыря в Арзамасе, возобновление в нем монашеской жизни</p>
					<p><b>1995 год</b> — официально закреплено название города Сарова</p>
					<p><b>1998 год</b> — освящение главного престола Преображенского собора в Дивееве</p>
					<p><b>2004 год</b> — начало возрождения Спасо-Преображенского мужского монастыря в Арзамасе</p>
					<p><b>2004 год —  2006 год</b> — возобновление монашеской жизни в Саровской пустыни</p>
				</div>,
		},
		{
			id: "3ki3-a5d9-4450-b93b-b941a1765i87y24d29d",
			img: {
				src: "https://storage.yandexcloud.net/serafim-uploads/content/2020/10/27/20._d33eeb48-000a-4315-8e88-fe8c9d8dcece.jpg",
				alt: "Начало строительства Благовещенского собора в Дивееве",
				type: "mac",
			},
			title: "Начало строительства Благовещенского собора в Дивееве",
			description:
				<div>
					<p><b>2012 год</b> — начало строительства Благовещенского собора на территории Серафимо- Дивеевского монастыря</p>
				</div>,
		},
		{
			id: "3ki3-a5d9-4450-b93b-bgfyy941a1765i87y24d29d",
			img: {
				src: "https://storage.yandexcloud.net/serafim-uploads/content/2020/10/27/22._cd78e6ec-0cff-421b-83ac-3ffdc939395a.jpg",
				alt: "Музей Русского патриаршества",
				type: "mac",
			},
			title: "Музей Русского патриаршества",
			description:
				<div>
					<p><b>2013 год</b> — открытие Музея русского патриаршества в Арзамасе</p>
				</div>,
		},
		{
			id: "3ki3-a5d9-4450-b93b-b941a1765fghfgi87y24d29op09d",
			img: {
				src: "https://storage.yandexcloud.net/serafim-uploads/content/2020/10/27/21._3025370b-3165-48ef-8d28-bbcd2b6b93ea.jpg",
				alt: "Начало строительства работ по возрождению Успенского собора",
				type: "mac",
			},
			title: "Начало строительства работ по возрождению Успенского собора",
			description:
				<div>
					<p><b>2016 год</b> — начало строительных работ по возрождению Успенского собора на территории Саровской пустыни</p>
					<p><b>2018 год</b> — создание в Нижегородской области паломническо-туристического кластера «Арзамас-Дивеево-Саров» при поддержке Президента Российской Федерации Владимира Путина и по благословению Святейшего Патриарха Московского и всея Руси Кирилла</p>
				</div>,
		},
	];

	// const borderType = {
	// 	old: "https://storage.yandexcloud.net/serafim-uploads/2020/06/11/content1_f4ff35f3-43d3-471f-8478-4ce9bdd5fd41.png",
	// 	transitor: "https://storage.yandexcloud.net/serafim-uploads/2020/06/11/content2_30949a7b-6976-4b0e-9618-9e9dae6ccebb.png",
	// 	tv: "https://storage.yandexcloud.net/serafim-uploads/2020/06/11/content3_0019e302-d7d6-4f92-84f9-00e364071fcd.png?X",
	// 	mac: "https://storage.yandexcloud.net/serafim-uploads/2020/06/11/content4_e74a4e62-7904-44ae-912a-0805211ed139.png",
	// };

	// const borderStyle = {
	// 	mobile: {
	// 		width: "100%",
	// 		height: "auto",
	// 	},
	// 	desktop: {
	// 		width: "100%",
	// 		height: 400,
	// 	},
	// };

	const imgStyle = {
		// position: "absolute",
		width: "100%",
		height: "100%",
		objectFit: "cover",
	};

	useEffect(() => {
		removePreloader();
	}, []);

	useEffect(() => {
		if (typeof window !== "undefined") {
			const root = document.getElementById("root-main");

			if (root) {
				if (root.offsetHeight - scroll.top > 500) {
					setOffset(100);
				} else {
					setOffset(-100);
				}
			}
		}
	}, [scroll]);

	function getCarousel() {
		return (
			<SCarousel
				containerInitialClass={"history"}
				getSwiper={setSlider}
				options={{
					effect: "fade",
					allowTouchMove: false,
					onlyExternal: true,
				}}
			>
				{items.map(({ id, img }) =>
					<div
						key={id}
						style={{
							position: "relative",
							height: "auto",
							textAlign: "center",
						}}
					>
						<img
							style={
								Object.assign({},
									{
										width: "100%",
										height: 400,
										position: isMobile ? "absolute" : "inherit",
									},
									imgStyle)
							}
							src={get(img, "src", "")}
							alt=""
							className={"img-img"}
						/>
						{/* <img
						style={Object.assign({},
							{
								position: "relative",
								zIndex: 10,
							},
							borderStyle[isMobile ? "mobile" : "desktop"],
						)}
						className={"img-border"}
						src={borderType[img.type]}
					/> */}
					</div>)}
			</SCarousel>
		);
	}

	return (
		<Pages entity={get(seoData, "history_page", {})}>
			<ContentBlock key={"breadcrumbs"}>
				<div className={"container"}>
					<Breadcrumbs currentLabel={"История кластера"} />
				</div>
			</ContentBlock>
			<ContentBlock key={"main-content"}>
				<div ref={container} className={"container"}>
					<h1>
						История кластера «Арзамас-Дивеево-Саров» в датах
					</h1>
					<TextBlock>
						<div id={"sooqa"} ref={block} className={"row hitory"}>
							<div
								className="col-xs-12 col-md-6 col-lg-6 history-image"
								style={{ minHeight: 1 }}>
								<Anchor
									targetOffset={isMobile ? 400 : 100}
									onChange={id => {
										const slide = items.findIndex(item => item.id === id.replace("#", ""));

										if (slider && slide >= 0) {
											slider.slideTo(slide + 1);
										}
									}}
									style={{ display: "none" }}
								>
									{items.map(({ id }) => <Link key={id} href={`#${id}`} title={id} />)}
								</Anchor>
								{
									!isMobile
										? (
											<Affix offsetTop={offset}>
												{ getCarousel() }
											</Affix>
										) : getCarousel()
								}
							</div>
							<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 history-text">
								{items.map(({ id, title, description }, idx) =>
									<div
										id={id}
										key={id}
										className={"history-text__item"}
										style={{
											paddingTop: idx === 0 && isMobile ? 30 : "initial",
										}}
									>
										<h1 className={"history-text__title"}>{title}</h1>
										<div>
											{description}
										</div>
									</div>)}
							</div>
						</div>
					</TextBlock>
				</div>
			</ContentBlock>
		</Pages>
	);
}

History.propTypes = {
	data: PropTypes.object,
};
