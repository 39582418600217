import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Swiper from "swiper";
import cx from "classnames";

import "swiper/css/swiper.min.css";

export default function Carousel({
	containerInitialClass,
	containerClassName,
	wrapperClassName,
	getSwiper,
	options,
	children,
}) {
	const carousel = useRef();
	const [swiper, setSwiper] = useState(null);

	useEffect(() => {
		setSwiper(
			new Swiper(
				`.${containerInitialClass}`,
				Object.assign(
					{},
					{
						slidesPerView: "auto",
						loop: true,
						spaceBetween: 80,
						navigation: {
							nextEl: ".swiper-button_next",
							prevEl: ".swiper-button_prev",
						},
					},
					options,
				),
			),
		);
	}, [containerClassName]);

	useEffect(() => {
		if (swiper) {
			getSwiper(swiper);
		}
	}, [swiper]);

	function modifyChildren(child) {
		const props = {
			className: cx("swiper-slide", child.props.className),
		};

		return React.cloneElement(child, props);
	}

	return (
		<div
			ref={carousel}
			className={cx("swiper-container", containerInitialClass, containerClassName)}
		>
			<div className={`swiper-wrapper ${wrapperClassName}`}>
				{React.Children.map(children, child => modifyChildren(child))}
			</div>
			<div className="swiper-button swiper-button_next"></div>
			<div className="swiper-button swiper-button_prev"></div>
		</div>
	);
}

Carousel.propTypes = {
	containerInitialClass: PropTypes.string,
	containerClassName: PropTypes.string,
	wrapperClassName: PropTypes.string,
	getSwiper: PropTypes.func,
	options: PropTypes.object,
	children: PropTypes.node,
};

Carousel.defaultProps = {
	containerInitialClass: "swiper-react-carousel",
	containerClassName: "",
	wrapperClassName: "",
	getSwiper: () => Function,
	object: {},
	children: [],
};
